<template>

    <div class="w-full h-full px-3 py-4"> 
        
        <div class="h-auto w-full overflow-auto flex-col">

            <All model="Representantinfo" :immediate="true" :config="queryinfo" v-slot="{data:info, loading:loadingInfo}">

                <div v-if="!loadingInfo" class="">

                    <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">

                        <readmessages v-if="permisions" endpoint="Representantmessage" :querymessage="querymessage" />

                        <All :model="model" :immediate="true" :config="config" :query="query" v-slot="{data, loading}">

                            <div v-if="!loading" class="h-auto pb-2">

                                <div v-if="!preciomedio && !margen" class="h-auto w-full mt-2"  :class="{'grid grid-cols-2 gap-2': !facturation && !metroscuadrados}" >
                                    <billing v-if="!metroscuadrados" :data="data.BubbleSale" :model="model2" :flag="false"
                                        :config="configFacturation" :lastSale="info.LastSaleDate" @openFacturation="openFacturation" />
                                    <meters v-if="!facturation" :data="data.BubbleMeter" :model="model2" :config="configMetros"
                                        :lastSale="info.LastSaleDate" @openMeters="openMeters" />
                                </div>

                                <div class="h-auto w-full">
                                    <div v-if="!facturation && !metroscuadrados" class="h-auto mt-2"  :class="{'grid grid-cols-2 gap-2': !margen && !precimedio}">
                                        <halfprice v-if="!margen" :data="data.BubbleMediumPrice" :model="model2"
                                            :config="configPreciomedio" :lastSale="info.LastSaleDate" @fromhalfprice="openHalfPrice"
                                            :isOpen="preciomedio" />
                                        <margin v-if="!preciomedio && user.rol.Name !== 'Representante'" :data="data.BubbleMargin"
                                            :model="model2" :config="configMargen" :lastSale="info.LastSaleDate" @openMargen="openMargen"
                                            :isOpen="margen" />
                                    </div>
                                </div>

                            </div>

                            <div v-else class="h-32 relative">
                                <loader :loading="loading" />
                            </div>

                        </All>

                    </div>
                    
                    <div class="absolute bottom-0 left-0 h-18 w-full">

                        <div class="h-full flex flex-row p-2 bg-transparent" :class="{'w-full': mobile, 'w-1/2 m-auto': !mobile}">
                        
                            <div class="h-full w-full px-4 space-x-6 rounded-2xl flex flex-row justify-start items-center shadow_box" :class="'bg-module-'+mode">
                            
                                <div class="h-full w-full flex flex-col justify-center items-center cursor-pointer">
                                    <div class="h-8 w-8 rounded-full border-2 flex flex-col justify-center items-center" :class="'border-dfont-'+mode" @click="toKpis()">
                                        <img :src='"/img/kpis_" + mode + ".svg"' class="h-4">
                                    </div>
                                    <span class="text-2xs font-medium" :class="'text-dfont-'+mode">KPIs</span>
                                </div>

                                <div class="h-full w-full flex flex-col justify-center items-center cursor-pointer">
                                    <div class="h-8 w-8 rounded-full border-2 flex flex-col justify-center items-center" :class="'border-dfont-'+mode" @click="toVisits()">
                                        <img :src='"/img/visits_" + mode + ".svg"' class="h-4">
                                    </div>
                                    <span class="text-2xs font-medium" :class="'text-dfont-'+mode">Visitas</span>
                                </div>

                                <div class="h-full w-full flex flex-col justify-center items-center cursor-pointer">
                                    <div class="h-8 w-8 rounded-full border-2 flex flex-col justify-center items-center" :class="'border-dfont-'+mode" @click="$router.push( { name: 'Representantclients'} )">
                                        <img :src='"/img/clients_" + mode + ".svg"' class="h-4">
                                    </div>
                                    <span class="text-2xs font-medium" :class="'text-dfont-'+mode">Clientes</span>
                                </div>
                                
                            </div>
                            
                            <div v-if="rolpermisions" class="h-auto w-auto mx-4 flex flex-col justify-center items-center absolute right-0 bottom-1" :class="{'': !mobile, '-mt-16': mobile}">

                                <div class="h-12 w-12  rounded-full bg-red flex flex-col justify-center items-center" @click="toTops()">
                                    <i class="mdi mdi-filter-variant text-white text-2xl font-semibold"></i>
                                </div>

                            </div>
                        
                        </div>

                    </div>
                
                </div>

                <div v-else class="h-32 relative">
                    <loader :loading="loadingInfo" />
                </div>

            </All>

            <div class="h-12"></div>

        </div>

    </div>

</template>

<script>
    import { state } from '@/store';
    import solidgauge from '@/components/solidgauge.vue';
    import { All } from '@/api/components';
    import loader from '@/components/loader.vue';
    import billing from '@/components/billing.vue';
    import meters from '@/components/meters.vue';
    import halfprice from '@/components/halfprice.vue';
    import margin from '@/components/margin.vue';
    import readmessages from '@/components/readmessages.vue';

    export default {
        name: 'representante',
        components:{
            solidgauge,
            All,
            loader,
            billing,
            meters,
            halfprice,
            margin,
            readmessages
        },
        data() {
            return {
                preciomedio: false,
                margen: false,
                facturation: false,
                metroscuadrados: false
            }
        },
        methods: {
            toKpis() { this.$router.push({name: 'kpisrepresentante'}) },
            openFacturation() {
                this.facturation = !this.facturation
                this.metroscuadrados = false
            },
            openMeters() {
                this.metroscuadrados = !this.metroscuadrados
                this.facturation = false
            },
            openPreciomedio() {
                this.preciomedio = !this.preciomedio
                this.margen = false
            },
            openMargen() {
                this.margen = !this.margen
                this.preciomedio = false
            },
            openHalfPrice() { this.preciomedio = !this.preciomedio },
            toVisits() { this.$router.push({ name: 'representantvisits' }) },
            toTops() { this.$router.push( { name: 'representanttops' } ) },
            rolpermisions() {
                if (this.rol === 'Propiedad') { return true }
                else
                    if (this.rol === 'Gerente') { return true }
                    else
                        if (this.rol === 'Comercial') { return true }
                        else if (this.rol === 'Representante') { return true }
            }
        },
        computed: {
            monthName(){
                return this.$moment({M: state.month -1 }).format('MMMM')
            },
            user() { return state.user },
            employee() { return state.employeSelected },
            idEmployee() { return state.idEmployeeSelected },
            config() {
                if (this.user.rol.Name !== 'Representante') {
                    if (this.user.rol.Name !== 'Comercial') {
                        if (this.$route.params.representant) {
                            return{
                                data: {
                                    Company: state.idCompany,
                                    SaleType: state.saleTypeSelected,
                                    Employee: this.$route.params.representant,
                                    Zone: state.idZone,
                                
                                },
                            }
                        }
                        else {
                            return {
                                data: {
                                    Company: state.idCompany,
                                    SaleType: state.saleTypeSelected,
                                    Employee: state.idEmployeeSelected,
                                    Zone: state.idZone,
                            
                                },
                            }
                        }
                    }
                    else {
                        return {
                            data: {
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                IdUser: state.user.id,
                                Employee: state.idEmployeeSelected,
                                Zone: state.idZone,
                           
                            }
                        }

                    }
                }
                else {
                    if (state.idCompany) {
                        return {
                            data: {
                                Company: state.idCompany,
                                // SaleType: state.saleTypeSelected,
                                Employee: state.idEmployeeSelected,
                                // Zone: state.idZone
                                month: state.month
                            }
                        }

                    }
                    else {
                        return {
                            data: {
                                Employee: state.idEmployeeSelected,
                               
                            }
                        }
                    }
                }
            },
            zone() { return state.zoneSelected },
            configFacturation() {
                if (this.user.rol.Name !== 'Representante') {
                    return {
                        data: {
                            Dimension: 0,
                            Employee: state.idEmployeeSelected,
                            Company: state.idCompany,
                            Zone: state.idZone,
                            SaleType: state.saleTypeSelected,
                            period: state.period,
                            month: state.month
                        },
                    };
                }
                else {
                    return {
                        data: {
                            Dimension: 0,
                            Company: state.idCompany,
                            Employee: state.idEmployeeSelected,
                            SaleType: state.saleTypeSelected,
                            period: state.period,
                            Zone: state.idZone,
                            month: state.month
                        }
                    }
                }
            },
            configMetros() {
                if (this.user.rol.Name !== 'Representante') {
                    return {
                        data: {
                            Dimension: 1,
                            Employee: state.idEmployeeSelected,
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                          
                        },
                    };
                }
                else {
                    return {
                        data: {
                            Dimension: 1,
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            Employee: state.idEmployeeSelected,
                       
                        }
                    }
                }
            },
            configPreciomedio() {
                if (this.user.rol.Name !== 'Representante') {
                    return {
                        data: {
                            Dimension: 2,
                            Employee: state.idEmployeeSelected,
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            
                        },
                    };
                }
                else {
                    return {
                        data: {
                            Dimension: 2,
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            Employee: state.idEmployeeSelected,
                           
                        }
                    }
                }
            },
            configMargen() {
                if (this.user.rol.Name !== 'Representante') {
                    return {
                        data: {
                            Dimension: 3,
                            Employee: state.idEmployeeSelected,
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            period: state.period,
                            month: state.month
                        },
                    };
                }
                else {
                    return {
                        data: {
                            Dimension:3,
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            Employee: state.idEmployeeSelected,
                            period: state.period,
                            month: state.month
                        }
                    }
                }
            },
            query() {
                return {
                    period: state.period,
                    IndustrialGroup: state.industrialGroup,
                    month: state.month
                }
            },
            comercial() { return state.comercialSelected },
            model() {
                // if(this.user.rol.Name != 'Representante'){

                //     if(state.comercialSelected.Id){
                //         return 'Representantbubbles'
                //     } else {
                //         return 'RepresentantbubblesZone'
                //     }

                // } else {

                //     return 'RepresentantbubblesZone'

                // }
                return 'RepresentantbubblesZone'
            },
            model2() {
                if (this.user.rol.Name !== 'Representante') {
                    if (state.comercial && state.comercialSelected.Id) { return 'Representantbubblesdetailuser' }
                    else { return 'Representantbubblesdetailzone' }
                }
                else { return 'Representantbubblesdetailzone' }
            },
            querymessage() {
                if (state.user.rol.Name !== 'Comercial') {
                    return {
                        Company: state.idCompany,
                        // SaleType: state.saleTypeSelected,
                        Zone: state.idZone,
                        Employee: state.idEmployeeSelected
                    }
                }
                else {
                    return {
                        Company: state.idCompany,
                        SaleType: state.saleTypeSelected,
                        Zone: state.idZone,
                        Employee: state.employeSelected.Id
                    }
                }
            },
            rol() { return state.user.rol.Name },
            permisions() {
                if (state.user.rol.Name === 'Representante' || state.user.rol.Name === 'Comercial') { return true }
                else { return false }
            },
            zona() { return state.zone },
            queryinfo() {
                if (state.user.rol.Name !== 'Representante') {
                    return {
                        data: {
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            Employee: state.idEmployeeSelected
                        }
                    }
                }
                else {
                    if (state.idCompany) {
                        return {
                            data: {
                                Company: state.idCompany,
                                Employee: state.idEmployeeSelected,
                            }
                        }
                    }
                    else {
                        return {
                            data: {
                                Employee: state.idEmployeeSelected
                            }
                        }
                    }
                }
            },
            configInfo() {
                if (this.modelComercial === 'ComercialZone') {
                    if (state.filterZoneComercial == null) {
                        return {
                            data: {
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                Zone: state.idZone,
                                // IdUser: state.userId,
                            }
                        }
                    }
                    else {
                        return {
                            data: {
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                Zone: state.idZone,
                                // IdUser: state.userId,
                            }
                        }
                    }
                }
                else
                    if (this.modelComercial === 'ComercialUser') {
                        return {
                            data:{
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                // IdUser: state.userId,
                                Zone: state.idZone
                            }
                        }
                    }
            },
            periodo() {
                switch (state.period) {
                    case 'month':
                        return this.$t("monthly")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    case 'year':
                        return this.$t("annual")
                        break;
                }
            },
            employeeName() { return state.employeeName },
            mode(){
                return state.mode
            },
            mobile(){
                return state.isMobile
            }
        }
    }
</script>
